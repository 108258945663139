<template>
	<div class="">
		<header class="header">
			<i class="title-icon iconfont icon-yishiguanli"></i>
			<h5 style="font-size: 16px;">成药管理</h5>
		</header>
		<div class="container">
			<div class="container-item">
				<el-form :model="editInfo" :rules="rule1" ref="rule" class="demo-ruleForm">
					<el-tabs>
						<el-tab-pane label="基础信息">
							<div class="gg-grid">
								<el-row :gutter="20">
									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<div class="search-container-fn-input">
												<el-form-item label="药品名称" prop="drug_name">
													<el-input placeholder="请输入药品名称" v-model="editInfo.drug_name"
														size="small" class="ipt">
													</el-input>
												</el-form-item>
											</div>
										</div>
									</el-col>
									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<div class="search-container-fn-input">
												<el-form-item label="是否处方药" prop="pres_not">
													<el-select style="width: 112%;" v-model="editInfo.pres_not"
														clearable placeholder="请选择" size="small">
														<el-option v-for="item in isOrNotOptions" :key="item.value"
															:label="item.label" :value="item.value">
														</el-option>
													</el-select>
												</el-form-item>
											</div>
										</div>
									</el-col>
									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<div class="search-container-fn-input">
												<el-form-item label="药品首字母" prop="first_char">
													<el-input placeholder="请输入药品首字母" v-model="editInfo.first_char"
														size="small" class="ipt">
													</el-input>
												</el-form-item>
											</div>
										</div>
									</el-col>
									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<div class="search-container-fn-input">
												<el-form-item label="药品剂型" prop="form_code">
													<el-select style="width: 112%;" v-model="editInfo.form_code"
														clearable placeholder="请选择" size="small">
														<el-option v-for="item in drugForms" :key="item.value"
															:label="item.label" :value="item.value">
														</el-option>
													</el-select>
												</el-form-item>
											</div>
										</div>
									</el-col>
									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<div class="search-container-fn-input">
												<el-form-item label="药品规格" prop="specification">
													<el-input placeholder="请输入药品规格" v-model="editInfo.specification"
														size="small" class="ipt">
													</el-input>
												</el-form-item>
											</div>
										</div>
									</el-col>
									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<div class="search-container-fn-input">
												<el-form-item label="包装单位" prop="pack_unit">
													<el-select style="width: 112%;" v-model="editInfo.pack_unit"
														clearable placeholder="请选择" size="small">
														<el-option v-for="item in drugUnits" :key="item.value"
															:label="item.label" :value="item.value">
														</el-option>
													</el-select>
												</el-form-item>
											</div>
										</div>
									</el-col>
									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<div class="search-container-fn-input">
												<el-form-item label="用量单位" prop="dose_unit">
													<el-input v-model="editInfo.dose_unit" placeholder="如:g、丸、袋、支、片等"
														class="ipt" size="small"></el-input>
												</el-form-item>
											</div>
										</div>
									</el-col>
									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<div class="search-container-fn-input">
												<el-form-item label="生产厂家" prop="factory">
													<el-input placeholder="请输入生产厂家" v-model="editInfo.factory"
														size="small" class="ipt">
													</el-input>
												</el-form-item>
											</div>
										</div>
									</el-col>
									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<div class="search-container-fn-input">
												<el-form-item label="药品类型" prop="cate_id">
													<el-select style="width: 112%;box-sizing: border-box;"
														v-model="editInfo.cate_id" clearable placeholder="请选择"
														size="small">
														<el-option v-for="item in drugCategoryOptions" :key="item.value"
															:label="item.label" :value="item.value">
														</el-option>
													</el-select>
												</el-form-item>
											</div>
										</div>
									</el-col>
									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<div class="search-container-fn-input">
												<el-form-item label="是否为黑名单药品" prop="is_black">
													<el-select style="width: 112%;" v-model="editInfo.is_black"
														clearable placeholder="请选择" size="small">
														<el-option v-for="item in isOrNotOptions" :key="item.value"
															:label="item.label" :value="item.value">
														</el-option>
													</el-select>
												</el-form-item>
											</div>
										</div>
									</el-col>
									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<div class="search-container-fn-input">
												<el-form-item label="批文编号" prop="approval_number">
													<el-input placeholder="请输入批文编号" v-model="editInfo.approval_number"
														size="small" class="ipt">
													</el-input>
												</el-form-item>
											</div>
										</div>
									</el-col>
									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<div class="search-container-fn-input">
												<el-form-item label="建议零售价" prop="single_price">
													<el-input placeholder="请输入建议零售价" v-model="editInfo.single_price"
														size="small" class="ipt">
													</el-input>
												</el-form-item>
											</div>
										</div>
									</el-col>
									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<el-form-item label="用药禁忌" prop="contra">
												<el-input placeholder="请输入用药禁忌" v-model="editInfo.contra" size="small"
													class="ipt">
												</el-input>
											</el-form-item>
										</div>
									</el-col>
									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<el-form-item label="用药方法" prop="desc">
												<el-input size="small" placeholder="请输入用药方法" v-model="editInfo.desc"
													class="ipt">
												</el-input>
											</el-form-item>
										</div>
									</el-col>

									<el-col class="dashboard-container-col" :lg="12">
										<div class="search-container">
											<el-form-item label="排序" prop="sort">
												<el-input size="small" placeholder="请输入排序" v-model="editInfo.sort"
													class="ipt">
												</el-input>
											</el-form-item>
										</div>
									</el-col>
									<el-col class="dashboard-container-col" :lg="12" style="">
										<div class="search-container">
											<el-form-item prop="enable_status">
												<span class="status">状态</span>
												<div class="radio">
													<el-radio v-model="editInfo.enable_status" label="0">启用</el-radio>
													<el-radio v-model="editInfo.enable_status" label="1">禁用</el-radio>
												</div>
											</el-form-item>
										</div>
									</el-col>

									<el-col class="dashboard-container-col" :lg="12">
										<el-form-item label="药品备注" prop="remark">
											<el-input type="textarea" placeholder="请输入药品备注" v-model="editInfo.remark"
												style="width: 138%;"></el-input>
										</el-form-item>
									</el-col>


								</el-row>
							</div>
						</el-tab-pane>
						<el-tab-pane label="扩展信息">
							<div class="gg-grid">
								<div class="information-title">
									<div class="information-title-l">
										<div class="required">
										</div>
										<span>药品图片</span>
									</div>
								</div>
								<el-form :model="editInfo2">
									<div class="bbox">
										<span
											style="font-size: 14px;color: #606266;display: inline-block;width: 70px;">主图：</span>
										<el-form-item prop="overview_url" class="bbox">
											<div class="elUpload">
												<div class="delss" v-if="editInfo2.overview_url.length>0"
													@click="editInfo.overview_url = ''">
													<i class="el-icon-delete del"></i>
												</div>
												<div class="dels" v-else>
													<el-upload :action="actionPath" :data="qiniuData"
														:show-file-list="false" :on-success="uploadPhotoSuccess"
														:before-upload="beforeAvatarUpload">
														<i class="el-icon-plus avatar-uploader-icon"></i>
													</el-upload>
												</div>
												<div v-if="editInfo2.overview_url">
													<el-image :src="editInfo2.overview_url | filterCDNUrl"
														:preview-src-list="[filterCDNUrl(editInfo2.overview_url)]"
														class="avatar">
													</el-image>
												</div>
											</div>
										</el-form-item>
									</div>
									<div class="bbox mt-2">
										<span
											style="font-size: 14px;color: #606266;display: inline-block;width: 70px;">详情图：</span>
										<el-form-item prop="desc_pic" class="bbox">
											<div class="">
												<div>
													<div class="bbox">
														<div class="descimg mr-2"
															v-for="(img, index) in editInfo2.desc_pic" :key="index">
															<div class="delss" @click="delDescPic(index)">
																<i class="el-icon-delete del"></i>
															</div>
															<el-image class="img" :src="img ? img : filterCDNUrl"
																:preview-src-list="editInfo2.desc_pic">
															</el-image>
														</div>
														<div class="dels" v-if="editInfo2.desc_pic.length<5">
															<el-upload :action="actionPath" :data="qiniuData"
																:show-file-list="false"
																:on-success="uploadDescImgSuccess"
																:before-upload="beforeAvatarUpload">
																<i class="el-icon-plus avatar-uploader-icon"></i>
															</el-upload>
														</div>
													</div>
												</div>
												<!-- <div class="bbox">
													<div class="descimg mr-2 "
														v-for="(img, index) in editInfo2.desc_pic" :key="index">
														<el-image class="img" :src="img ? img : filterCDNUrl"
															:preview-src-list="editInfo2.desc_pic">
														</el-image>
													</div>
												</div> -->

											</div>
										</el-form-item>
									</div>
									<div class="information-title">
										<div class="information-title-l">
											<div class="required">
											</div>
											<span>药品介绍</span>
										</div>
									</div>
									<el-row :gutter="20">
										<el-col class="dashboard-container-col" :span="24">

											<div>
												<tinymce style="width:100%" v-model="editInfo2.content" :height="200" />
											</div>
										</el-col>
									</el-row>
								</el-form>
							</div>
						</el-tab-pane>
					</el-tabs>
				</el-form>
			</div>
			<div style="display: flex;align-items: center;justify-content: center;">
				<el-button size="small" @click="goBack">取消</el-button>
				<el-button type="primary" size="small" @click="saveForm">保存</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import config from "@/utils/config";
	import {
		getQNToken
	} from "@/api/basic";
	import Tinymce from '@/components/Tinymce';
	import {
		addcpmDrug
	} from "@/api/drugs2.0";
	export default {
		data() {
			return {
				editInfo: {},
				editInfo2: {
					overview_url: '',
					desc_pic: []
				},
				isOrNotOptions: [{
						label: "是",
						value: true,
					},
					{
						label: "否",
						value: false,
					},
				],
				drugCategoryOptions: [{
						label: "西药",
						value: 2
					},
					{
						label: "中成药",
						value: 3
					},
				],
				actionPath: "http://upload.qiniup.com",
				qiniuUrl: config.qiniuUrl,
				qiniuData: {
					token: "",
					key: "",
				},
				rule1: {
					drug_name: [{
						required: true,
						message: "请输入药品名称",
						trigger: "blur"
					}, ],
					pres_not: [{
						required: true,
						message: "请选择是否处方药",
						trigger: "change"
					}, ],
					first_char: [{
						required: true,
						message: "请输入药品首字母",
						trigger: "blur"
					}, ],
					form_code: [{
						required: true,
						message: "请选择药品剂型",
						trigger: "change"
					}, ],
					specification: [{
						required: true,
						message: "请输入药品规格",
						trigger: "blur"
					}, ],
					pack_unit: [{
						required: true,
						message: "请选择包装单位",
						trigger: "change"
					}, ],
					dose_unit: [{
						required: true,
						message: "请选择用量单位",
						trigger: "blur"
					}, ],
					factory: [{
						required: true,
						message: "请输入生产厂家",
						trigger: "blur"
					}, ],
					cate_id: [{
						required: true,
						message: "请选择药品类型",
						trigger: "change"
					}, ],
					is_black: [{
						required: true,
						message: "请选择是否为黑名单药品",
						trigger: "change",
					}, ],
					approval_number: [{
						required: true,
						message: "请输入药品批文编号",
						trigger: "blur"
					}, ],
					single_price: [{
						required: true,
						message: "请输入建议零售价",
						trigger: "blur"
					}, ],
					contra: [{
						required: true,
						message: "请输入用药禁忌",
						trigger: "blur"
					}, ],
					desc: [{
						required: true,
						message: "请输入用方法",
						trigger: "blur"
					}],
					remark: [{
						required: true,
						message: '请输入药品备注',
						trigger: "blur"
					}],
					sort: [{
						required: true,
						message: '请输入排序',
						trigger: "blur"
					}]
				},
			}
		},
		components: {
			Tinymce
		},
		created() {
			this.getToken()
		},
		mounted() {
			//剂型列表
			this.$store.dispatch('dictionary/getDicData', [{
				stateName: 'drugs',
				api: '/admin/drug/getDrugDicts'
			}]);
		},
		computed: {
			...mapState({
				drugUnits: (state) => state.dictionary.drugs.drug_unit,
				drugForms: (state) => state.dictionary.drugs.drug_dosage,
			}),
		},
		methods: {
			//保存基础信息
			saveForm() {
				this.$refs['rule'].validate((valid, object) => {
					if (!valid) {
						let item;
						for (let key in object) {
							item = object[key][0];
							break;
						}
						this.$message({
							type: "error",
							message: item.message,
						});
						return;
					} else {
						let params = {};
						// let curDrugForm = this.drugForms.find(v => v.value == this.editInfo.form_name)
						// console.log(curDrugForm,888)
						params = {
							...this.editInfo,
							...this.editInfo2
						};
						params.pres_not == false ? params.pres_not = '0' : params.pres_not = '1'
						params.is_black == false ? params.is_black = '0' : params.is_black = '1'
						// params.form_name = curDrugForm ? curDrugForm.form_name : ''

						console.log(params)
						//编辑

						addcpmDrug(params)
							.then((response) => {
								let data = response;
								if (data.code == 200) {
									this.$message({
										type: "success",
										message: "添加成功！",
									});
									this.goBack()
								} else {
									this.$message({
										type: "error",
										message: data.message,
									});
								}
								this.fullscreenLoading = false;
							})
							.catch((error) => {
								this.$message({
									type: "error",
									message: error.response.data.message,
								});
								this.fullscreenLoading = false;
							});

						this.fullscreenLoading = true;
					}
				});
			},
			goBack() {
				this.$router.push({
					name: 'drugsList'
				})
			},
			//删除详情图
			delDescPic(index) {
				this.editInfo2.desc_pic.splice(index, 1)
			},
			filterCDNUrl(url) {
				if (!url) {
					return "";
				} else {
					if (url.indexOf("http") > -1) {
						return url;
					} else {
						return config.qiniuUrl + url;
					}
				}
			},
			//获取token
			getToken() {
				getQNToken()
					.then((response) => {
						let data = response;
						if (data.code == 200) {
							this.qiniuData.token = data.data.token;
						}
					})
					.catch((error) => {
						console.log(error);
					});
			},

			beforeAvatarUpload(file) {
				let name = new Date().getTime() + "_" + file.name //给file.name加上时间戳保证图片命名不重复
				this.qiniuData.key = name; // 通过设置key为文件名，上传到七牛中会显示对应的图片名
				// debugger
				const isJPG = file.type === "image/jpeg" || file.type === "image/png";
				const isLt10M = file.size / 1024 / 1024 < 10;

				if (!isJPG) {
					this.$message.error("上传头像图片只能是 JPG 格式!");
				}
				if (!isLt10M) {
					this.$message.error("上传头像图片大小不能超过 2MB!");
				}
				return isJPG && isLt10M;
			},
			uploadPhotoSuccess(res) {
				console.log(res)
				this.editInfo2.overview_url = res.key;
				console.log(this.editInfo2.overview_url)
			},

			uploadDescImgSuccess(res) {
				console.log(res)
				this.editInfo2.desc_pic.push('https://pic.hngegejk.cn//' + res.key);
				console.log(this.editInfo.desc_pic)
			},
		}
	}
</script>

<style scoped lang="scss">
	.container {
		background-color: white;
		height: 800px;
	}

	.container-item {
		padding: 20px 60px;

	}

	.gg-grid {
		padding-top: 0 !important;
		max-height: 650px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.search-container {
		margin-bottom: 0;
	}

	.header {
		display: flex;
		align-items: center;
		height: 26px;
		margin-bottom: 10px;

		i {
			width: 25px;
			height: 25px;
			background-color: #2632fb;
			color: white;
			line-height: 25px;
			text-align: center;
			border-radius: 4px;
			margin-right: 8px;
		}
	}

	.descimg {
		width: 120px;
		height: 120px;
		display: block;

		position: relative;
		overflow: hidden;



		.img {
			width: 120px;
			height: 120px;
		}
	}

	.ipt {
		width: 120%;
		box-sizing: border-box;
	}

	.elUpload {
		text-align: center;
		width: 120px;
		height: 120px;
		position: relative;

		.el-upload {
			width: 100%;
			height: 100%;

			.avatar-uploader-icon {
				width: 100%;
				height: 100%;
			}
		}

		.avatar {
			max-width: 100%;
			height: 120px;

		}
	}

	.avatar {
		width: 120px;
		height: 120px;
		display: block;
	}

	.avatar-uploader-icon {
		background-color: #F6f6f6;
		font-size: 28px;
		color: #8c939d;
		width: 120px !important;
		height: 120px;
		line-height: 120px;
		text-align: center;
	}

	.del {
		position: absolute;
		right: 45px;
		top: 45px;
		color: #fff;
		font-size: 30px;
		cursor: pointer;
	}

	.dels {
		border: 1px dashed #4ebbfc;
		box-sizing: border-box;
		width: 122px;
		height: 122px;
	}

	.delss {
		opacity: 0.5;
		background-color: #000;
		width: 122px;
		height: 122px;
		position: absolute;
		z-index: 100;

		i {
			z-index: 101;
		}
	}

	.information-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		padding: 16px 0 6px 0;

		span {
			display: inline-block;
			font-weight: bold;
			margin-left: 10px;
			margin-bottom: 20px;
		}
	}

	.required {
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #2362FB;
		position: absolute;
		top: 20px;

	}

	.status::before {
		content: '*';
		color: red;
	}

	.status {
		font-size: 14px;
		color: #606266;
		font-weight: bold;
		display: inline-block;

	}
	.dashboard-container-col{
		height: 103px;
	}
</style>
